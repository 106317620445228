import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDHprs4iuAM5eWfJoXPKmrxHDvBvSODYLo",
    authDomain: "knockdown-bridge.firebaseapp.com",
    databaseURL: "https://knockdown-bridge.firebaseio.com",
    projectId: "knockdown-bridge",
    storageBucket: "knockdown-bridge.appspot.com",
    messagingSenderId: "728054113533",
    appId: "1:728054113533:web:601cad203c54abb6ac4a94",
    measurementId: "G-Y3LFYMFJYE"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;